// Generated by Framer (508aa67)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Jb3hJQzu8"];

const serializationHash = "framer-2wiTF"

const variantClassNames = {Jb3hJQzu8: "framer-v-1qmkdmj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Jb3hJQzu8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 36.36363557547579, intrinsicWidth: 63.63636225708263, pixelHeight: 80, pixelWidth: 140, src: "https://framerusercontent.com/images/AbrqvWFeTeEdkmSA7ENc3PA87yk.png"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1qmkdmj", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Jb3hJQzu8"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2wiTF.framer-1sugkv5, .framer-2wiTF .framer-1sugkv5 { display: block; }", ".framer-2wiTF.framer-1qmkdmj { height: 38px; overflow: visible; position: relative; width: 65px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 37.5
 * @framerIntrinsicWidth 65
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerIb5_oKZb9: React.ComponentType<Props> = withCSS(Component, css, "framer-2wiTF") as typeof Component;
export default FramerIb5_oKZb9;

FramerIb5_oKZb9.displayName = "Saas logos";

FramerIb5_oKZb9.defaultProps = {height: 37.5, width: 65};

addFonts(FramerIb5_oKZb9, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})